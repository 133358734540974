import React from 'react'
import Layout from "../components/Layout"

export default function error() {
    return (
        <Layout>
            <h1 style={{display: 'flex', justifyContent: 'center'}} >404 not found</h1>
        </Layout>
    )
}
